import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { firstValueFrom } from 'rxjs';
import { DeleteDeviceDialogComponent } from 'src/app/delete-device-dialog/delete-device-dialog.component';
import { DeviceDialogComponent } from 'src/app/device-dialog/device-dialog.component';
import { ApiGatewayService, DeviceMessage, RegisteredDevice } from 'src/app/services/api-gateway.service';
import { InfoMessageService, delay, emitInfoMessage } from 'src/app/services/info-message.service';
import { KeycloakUserService } from 'src/app/services/keycloak-user.service';

export interface DialogData {
  dialogType : 'update' | 'register'
  deviceData : RegisteredDevice
}

export interface DialogRemoveData {
  deviceData : RegisteredDevice
}

@Component({
  selector: 'tab-devices',
  templateUrl: './tab-devices.component.html',
  styleUrls: ['./tab-devices.component.scss']
})
export class TabDevicesComponent {
  registeredDevicesTableColumns : string[] = ['id', 'lorawan_address', 'nwk_skey', 'description', 'last_fcnt', 'last_seen','buttons']
  registeredDevices: MatTableDataSource<RegisteredDevice>;
  toUpdateDevice? : RegisteredDevice;
  selectedDevice? : RegisteredDevice; 
  selectedDeviceMessagesTableColumns : string[] = ['id', 'reception_date', 'hub_date','done_date','payload','status']
  // selectedDeviceMessages : DeviceMessage[] = [];
  selectedDeviceMessages : MatTableDataSource<DeviceMessage>;
  displayedColumns : string[] = ['position', 'name', 'weight', 'symbol'];
  maxUserDevices : number = 0;
  
  @ViewChild('sorterDevices') sortDevices = new MatSort();
  @ViewChild('sorterMessages') sortMessages = new MatSort();

  constructor(private infoMessageService: InfoMessageService,
    private gatewayApiService: ApiGatewayService,
    private keycloakUserService: KeycloakUserService,
    public dialog: MatDialog){
      this.registeredDevices = new MatTableDataSource();
      this.selectedDeviceMessages = new MatTableDataSource();
    }

  async ngOnInit(){
    // Get Devices
    this.getRegisteredDevicesUser();
    this.maxUserDevices = this.keycloakUserService.getUserMaxDevices();
  }

  updateSelectedDeviceMessages(){
    if (this.selectedDevice) this.getDeviceMessages(this.selectedDevice)
  }

  async getRegisteredDevicesUser(){
    await this.gatewayApiService.getRegisteredDevices()
    .then((data) => {
      if (data.body){
        this.registeredDevices = new MatTableDataSource(data.body);
        this.registeredDevices.sort = this.sortDevices;
        if (data.body.length == 0){
          emitInfoMessage("No devices found for user", this.infoMessageService)
        } else {
          this.registeredDevices.data.forEach((device : RegisteredDevice) => {
            if (device.last_seen) device.last_seen = device.last_seen.replace('T',' ').replace('Z','');
            })
        }
      }
    })
    .catch((error) => {
      emitInfoMessage(error.status +": "+ error.statusText, this.infoMessageService)
    })
  }

  async deleteRegisteredDevice(toDeleteDevice:RegisteredDevice){
    if (this.selectedDevice === toDeleteDevice){
      this.selectedDevice = undefined;
    }
    await this.gatewayApiService.deleteRegisteredDevice(toDeleteDevice)
    .then(() => {
      emitInfoMessage("Deleted device", this.infoMessageService)
      delay(500);
      this.getRegisteredDevicesUser();
    })
    .catch((error) => {
      emitInfoMessage(error.status +": "+ error.statusText, this.infoMessageService)
    })
  }

  async confirmDelete(toDeleteDevice:RegisteredDevice) {
    // Open dialog with existing or new made device as data
    const dialogRef = this.dialog.open(DeleteDeviceDialogComponent, {
      data : { deviceData : toDeleteDevice}
    });

    // Wait for first value from dialog and save
    let dialogResult = await firstValueFrom(dialogRef.afterClosed())

    // If dialog was clicked on submit (returning a true bool), i.e. not cancelled
    if (dialogResult){
      this.deleteRegisteredDevice(toDeleteDevice)
    }
  }

  async getDeviceMessages(device : RegisteredDevice){
    await this.gatewayApiService.getDeviceMessages(device)
    .then((data) => {
      if (data.body){
        this.selectedDeviceMessages = new MatTableDataSource(data.body.reverse())
        this.selectedDeviceMessages.sort = this.sortMessages;
        this.selectedDeviceMessages.data.forEach((deviceMessage : DeviceMessage) => {
          deviceMessage.reception_date = deviceMessage.reception_date.replace('T',' ').replace('Z','');
          deviceMessage.hub_date = deviceMessage.hub_date.replace('T',' ').replace('Z','');
          deviceMessage.done_date = deviceMessage.done_date.replace('T',' ').replace('Z','');
          })
        if (data.body.length == 0){
          emitInfoMessage("No messages found for device", this.infoMessageService)
        }
      }
    })
    .catch((error) => {
      emitInfoMessage(error.status +": "+ error.statusText, this.infoMessageService)
    })
  }

  async openDialogDevice(dialogType : 'update' | 'register', toUpdateDevice? : RegisteredDevice) {
    // For register we make a new empty device
    if (!toUpdateDevice) toUpdateDevice = {description:'', lorawan_address:'', nwk_skey: ''}

    // Open dialog with existing or new made device as data
    const dialogRef = this.dialog.open(DeviceDialogComponent, {
      data : {dialogType : dialogType, deviceData : toUpdateDevice}
    });

    // Wait for first value from dialog and save
    let dialogResult = await firstValueFrom(dialogRef.afterClosed())

    // If dialog was clicked on submit (returning a true bool), i.e. not cancelled
    if (dialogResult){
      if (dialogType == 'register'){
        this.addRegisteredDeviceDialog(toUpdateDevice)
      } else if (dialogType == 'update'){
        this.updateRegisteredDeviceDialog(toUpdateDevice)
      } else {
        emitInfoMessage("Cannot handle return values from dialog", this.infoMessageService)
      }
    } 
  }

  async addRegisteredDeviceDialog(newDevice : RegisteredDevice) {
    await this.gatewayApiService.addRegisteredDevice(newDevice)
    .then(() => {
      emitInfoMessage("Added device", this.infoMessageService)
      delay(500);
      this.getRegisteredDevicesUser();
    })
    .catch((error) => {
      emitInfoMessage(error.status +": "+ error.statusText, this.infoMessageService)
    })
  }

  async updateRegisteredDeviceDialog(toUpdateDevice : RegisteredDevice){
    if (!toUpdateDevice){
      emitInfoMessage("Error in updating device, no device found to edit", this.infoMessageService)
      return
    }
    await this.gatewayApiService.updateRegisteredDevice(toUpdateDevice)
    .then(() => {
      emitInfoMessage("Updated device", this.infoMessageService)
      delay(500);
      this.getRegisteredDevicesUser();
    })
    .catch((error) => {
      emitInfoMessage(error.status +": "+ error.statusText, this.infoMessageService)
    })
  }

}
