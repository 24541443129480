import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiGatewayService } from 'src/app/services/api-gateway.service';
import { delay } from 'src/app/services/info-message.service';
import { InfoMessageService, emitInfoMessage } from 'src/app/services/info-message.service';
import { KeycloakUserService } from 'src/app/services/keycloak-user.service';

export interface CardData {
  name : string,
  text : string,
  type : 'binary' | 'ls300-cli' | 'c-code',
  almanac? : string 
} 

export const CARD_DATA : CardData[] = [
  {
    name : "C source code",
    text : "Use this format if you want to copy paste the almanac data along the Arduino sketch.",
    type : "c-code"
  },{
    name : "Hexadecimal code",
    text : "Use this format if you want to copy paste the almanac data in hex format.",
    type : "binary"
  },{
    name : "LS300 CLI commands",
    text : "Use this format if you want to insert the almanac using the CLI on the LS300. Note that each command (5 total) should be entered individually.",
    type : "ls300-cli"
  }
];

@Component({
  selector: 'tab-almanac',
  templateUrl: './tab-almanac.component.html',
  styleUrls: ['./tab-almanac.component.scss']
})
export class TabAlmanacComponent {
  cardDatas : CardData[] = CARD_DATA;
  almanacVersions : string[] = [];
  previewingAlmanac : string | null = null;
  areButtonsAvailable : boolean = false;

  almanacDownloadForm = new FormGroup({
    almanacVersion: new FormControl<string|null>(null,Validators.required)
  })

  constructor(private infoMessageService: InfoMessageService,
              private gatewayApiService: ApiGatewayService,
              private keycloakUserService: KeycloakUserService){}

  async ngOnInit(){
    this.almanacVersions = this.keycloakUserService.getUserAllowedAlmanacs();
    this.almanacDownloadForm.controls.almanacVersion.setValue(this.almanacVersions[0]);
    this.updateCardDataWithAlmanacs();
  }

  async updateCardDataWithAlmanacs(){
    this.areButtonsAvailable = false;
    const almanacName :string = <string>this.almanacDownloadForm.controls.almanacVersion.value
    for (const cardData of this.cardDatas){
      await this.gatewayApiService.getAlmanacToDownload(almanacName, cardData.type)
      .then((data)=>{
        if (data.body == ''){
          cardData.almanac = undefined
        } else {
          cardData.almanac = data.body
        }
      })
      .catch((error) => {
        cardData.almanac = undefined
        emitInfoMessage("Could not get almanac: " + error.message, this.infoMessageService)
      })
    }
    await delay(10);
    this.areButtonsAvailable = true;
  }

  previewAlmanac(cardData : CardData) {
    this.previewingAlmanac = cardData.almanac? cardData.almanac : 'empty';
  }

  notifyCopied(type : 'c-code' | 'binary' | 'ls300-cli'){
    emitInfoMessage("Copied almanac in " + type + " format to Clipboard.",this.infoMessageService)
  }

}
