import { Component } from '@angular/core';

export interface ProfileData {
  username: string;
  maxdevices: number;
}


@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {
  username : string = 'John Doe'
}
