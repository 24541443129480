import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment.production';
import { ApiService } from './api.service';
import * as moment from 'moment';

export const ALMANAC_FORMATS : string[] = [
  'binary', 'ls300-cli', 'c-code'
]

export interface ServiceType{
  name : string,
  id : string
}

export const SERVICE_TYPES: ServiceType[] = [
  { name: 'Message Reception',
    id:'tx'},
  { name: 'Almanac Broadcast',
    id:'almanac_broadcast'},
];

export interface HardwareCapability{
  name : string,
  id : string
}

export const HARDWARE_CAPABILITIES: HardwareCapability[] = [
  { name: '868 MHz',
    id:'868'},
  { name: '915 MHz',
    id:'915'},
  { name: 'S-band',
    id:'sband'}
];

export interface HardwareCapabilityToConstellation{
  id : string,
  constellation_id : number
}

export const MAPPING_HARDWARE_CAPABILITY_TO_CONSTELLATION: HardwareCapabilityToConstellation[] = [
  { id:'868',
    constellation_id: 0},
  { id:'915',
    constellation_id: 0},
  { id:'sband',
    constellation_id: 1}
];

export interface MessageOfTheDayAPI {
  from : string,	
  id : number,	
  message_text : string,
  to : string,
}

export interface Connector{
  name : string,
  display_name : string
}

export interface ConnectorStatus{
  state : string
}

export interface AlmanacMetaData{
  renew_by : string,
  service_provider_mask :	string,
  tx_until :	string,
  use_until :	string,
  valid_from :string
}

export interface AlmanacPass{
  contact_end :	string,
  contact_start :	string,
  contact_tca :	string,
  max_elevation :	number,
  satellite : string,
  service : string
}

export interface ConstellationCode{
  name : string,
  bit_number : number,
}

export interface CountryCode{
  // Deprecated warning: id will be removed in the future
  name : string,
  id : number,
  code : string,
}

export interface RegisteredDevice{
  id?	: number,
  lorawan_address : string,
  nwk_skey : string,
  description : string,
  last_fcnt? :	string,
  last_seen? :	string
}

export interface DeviceMessage{
  id : number,
  reception_date :string	,	
  hub_date : string,
  done_date : string,
  payload : string,
  status : string
}

@Injectable({
  providedIn: 'root'
})
export class ApiGatewayService {
  private url:string = environment.url;
  private url_gateway:string = environment.url_gatewayApi ;

  constructor(private apiservice: ApiService) { }

  /**
   * Gets todays messages of the day.
   */
  public getMessagesOfTheDay() : Promise<HttpResponse<MessageOfTheDayAPI[]>> {
    const completeUrl: string = this.url_gateway + '/dashboard/messages'; 
    return this.apiservice.getFromApiPromise<MessageOfTheDayAPI[]>(completeUrl, new HttpHeaders())
  }

  /**
   * Gets connectors.
   */
  public getConnectors() : Promise<HttpResponse<Connector[]>> {
    const completeUrl: string = this.url_gateway + '/connectors'; 
    return this.apiservice.getFromApiPromise<Connector[]>(completeUrl, new HttpHeaders())
  }

  /**
   * Gets connector status
   */
  public getConnectorStatus(
    connectorName : string
  ) : Promise<HttpResponse< ConnectorStatus>> {
    let nameToQuery : string = encodeURI(connectorName)
    const completeUrl: string = this.url_gateway + '/connectors/'+nameToQuery+'/queue'; 
    return this.apiservice.getFromApiPromise<ConnectorStatus>(completeUrl, new HttpHeaders())
  }

  /**
   * Gets devices given the logged in user
   */
  public getRegisteredDevices() : Promise<HttpResponse<RegisteredDevice[]>> {
    const completeUrl: string = this.url_gateway + '/devices'; 
    return this.apiservice.getFromApiPromise<RegisteredDevice[]>(completeUrl, new HttpHeaders())
  }

  /**
   * Add (/register) device for user
   */
  public addRegisteredDevice(
    toRegisterDevice : RegisteredDevice
  ) : Promise<HttpResponse<RegisteredDevice[]>> {
    const completeUrl: string = this.url_gateway + '/devices'; 
    let urlParams = new URLSearchParams();
    urlParams.set("lorawan_address",toRegisterDevice.lorawan_address);
    urlParams.set("description",toRegisterDevice.description);
    urlParams.set("nwk_skey",toRegisterDevice.nwk_skey);
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this.apiservice.postApiPromise<RegisteredDevice[]>(completeUrl, urlParams.toString(), headers)
  }

  /**
   * Update (/patch) device of user
   */
  public updateRegisteredDevice(
    toUpdateDevice : RegisteredDevice
  ) : Promise<HttpResponse<RegisteredDevice[]>> {
    const completeUrl: string = this.url_gateway + '/devices/' + toUpdateDevice.id; 
    let urlParams = new URLSearchParams();
    urlParams.set("description",toUpdateDevice.description);
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this.apiservice.patchApiPromise<RegisteredDevice[]>(completeUrl, urlParams.toString(), headers)
  }
  
  /**
   * Delete device for user 
   */
  public deleteRegisteredDevice(
    toDeleteDevice : RegisteredDevice
  ) : Promise<HttpResponse<RegisteredDevice[]>> {
    const completeUrl: string = this.url_gateway + '/devices/' + toDeleteDevice.id; 
    return this.apiservice.deleteApiPromise<RegisteredDevice[]>(completeUrl, new HttpHeaders())
  }

  /**
   * Delete device messages
   */
  public getDeviceMessages(
    device : RegisteredDevice
  ) : Promise<HttpResponse<DeviceMessage[]>> {
    const completeUrl: string = this.url_gateway + '/devices/' + device.id + '/messages'; 
    return this.apiservice.getFromApiPromise<DeviceMessage[]>(completeUrl, new HttpHeaders())
  }

  /**
   * Gets pass prediction constellations options
   */
  public getPassPredictionConstellationsOptions() : Promise<HttpResponse<ConstellationCode[]>> {
    const completeUrl: string = this.url_gateway + '/dashboard/constellations'; 
    return this.apiservice.getFromApiPromise<ConstellationCode[]>(completeUrl, new HttpHeaders())
  }

  /**
   * Gets pass prediction country options
   */
  public getPassPredictionCountriesOptions() : Promise<HttpResponse<CountryCode[]>> {
    const completeUrl: string = this.url_gateway + '/dashboard/countries'; 
    return this.apiservice.getFromApiPromise<CountryCode[]>(completeUrl, new HttpHeaders())
  }

  /**
   * Gets passes
   */
  public getAlmanacPasses(
    almanacName : string,
    countryCode : string,
    latitude : number,
    longitude : number,
    serviceType? : string[],
    // constellationMask? : string[],
    hardwareCapabilities? : string[],
    dateFrom? : moment.Moment
  ) : Promise<HttpResponse<AlmanacPass[]>> {
    let queryAlmanac : string = encodeURI(almanacName)

    let queryParams = new HttpParams()
    if (serviceType) queryParams=queryParams.set('service_type',serviceType.join(','))
    // should be undefined for now
    // if (constellationMask) queryParams=queryParams.set('constellation_mask',constellationMask.join(',')) 

    let newHardwareCapabilities : string[] = [];
    if (hardwareCapabilities) hardwareCapabilities.forEach((hardwareCapability:string) => {
      if (hardwareCapability != 'sband') newHardwareCapabilities.push('ism'+hardwareCapability)
      else newHardwareCapabilities.push(hardwareCapability)
    })

    if (newHardwareCapabilities.length > 0) queryParams=queryParams.set('hardware_caps',newHardwareCapabilities.join(','))
    if (dateFrom) queryParams=queryParams.set('from',dateFrom.format('YYYY-MM-DD')+'T00:00:00Z')

    const completeUrl: string = this.url_gateway + '/almanacs/' 
                                                  + queryAlmanac + '/passes/'
                                                  + countryCode +'/'
                                                  + latitude + '/'
                                                  + longitude 
                                                  + '?' + queryParams.toString(); 
    // console.log(completeUrl);
    return this.apiservice.getFromApiPromise<AlmanacPass[]>(completeUrl, new HttpHeaders())
  }

  /**
   * Gets latest Alamanc in specific format
   */
  public getAlmanacToDownload(
    name: string,
    type: 'binary' | 'ls300-cli' | 'c-code'
  ) : Promise<HttpResponse<any>> {

    const completeUrl: string = this.url_gateway + '/download/almanacs/' + name; 

    let headers;
    switch (type) {
      case 'binary' :
        headers = new HttpHeaders().set("accept", "application/binary")
        break
      case 'ls300-cli' :
        headers = new HttpHeaders().set("accept", "vnd.lacuna/ls300-cli")
        break
      case 'c-code' :
        headers = new HttpHeaders().set("accept", "text/x-c")
        break
    }

    return this.apiservice.getFromApiPromiseAsText(completeUrl, headers)
  }

  public getDownloadIndex () : Promise<HttpResponse<any>> {
    const completeUrl: string = this.url + '/download/index.json';
    return this.apiservice.getFromApiPromise<any>(completeUrl, new HttpHeaders())
  }

  public getFileToDownload (url : string) : Promise<HttpResponse<any>> {
    const completeUrl: string = this.url + '/download/' + url;
    return this.apiservice.getFromApiPromise<any>(completeUrl, new HttpHeaders())
  }
}
