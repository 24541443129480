<div class="main-almanac">
    <div class="title-text">
        <h2 class="title-h2">The lacuna LPP2 Almanac</h2>
    </div>

    <div class="body-text">
        In case you want to download the<i> latest </i>LPP2 almanac for testing, you can preview or copy the format you like.
    </div>

    <div [formGroup]="almanacDownloadForm" class="inputs" >
        <mat-form-field *ngIf="almanacVersions.length > 1">
            <mat-label>Almanac Version</mat-label>
            <mat-select formControlName="almanacVersion" (selectionChange)="updateCardDataWithAlmanacs()">
            <mat-option *ngFor="let almanac of almanacVersions" [value]="almanac" >
                {{almanac}}
            </mat-option>
            </mat-select>
            <mat-error *ngIf="almanacDownloadForm.controls.almanacVersion.invalid">Invalid almanac</mat-error>
        </mat-form-field>  
    </div>

    <div class="cards">
        <div class="container">
            <mat-card 
                class="card" 
                *ngFor="let cardData of cardDatas">
                <mat-card-content 
                    class="rows" >
                    <div class="column-text">
                        <p><strong>{{cardData.name}}</strong></p>
                        <p>{{cardData.text}}</p>
                    </div>
                    <div class="column-buttons"
                        [matTooltipDisabled]="!(cardData.almanac == undefined)"
                        [matTooltip]="'No almanac found, so nothing to copy'">
                        <button mat-raised-button 
                            color="primary" 
                            [disabled]="(!areButtonsAvailable || (cardData.almanac == undefined))"
                            [cdkCopyToClipboard]="cardData.almanac? cardData.almanac : 'empty'"
                            (click)="notifyCopied(cardData.type)">Copy</button>
                        <button mat-raised-button 
                            color="primary" 
                            [disabled]="(!areButtonsAvailable || (cardData.almanac == undefined))"
                            (click)="previewAlmanac(cardData)">Preview</button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <mat-card *ngIf="previewingAlmanac" class="preview-card">
            <mat-card-title class="card-title">
                <div>
                    <strong>Preview of Almanac</strong>
                </div>
                <button class="close-button" mat-icon-button matTooltip="Close preview" (click)="previewingAlmanac=null" color="white">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-card-title> 
            
            <mat-card-content class="preview">
                {{previewingAlmanac}}
            </mat-card-content>
        </mat-card> 
    </div>
</div>