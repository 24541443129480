import { Component } from '@angular/core';
import { routes } from '../app-routing.module';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  toMakeButtons : any[] = [];
  
  constructor(){
    routes[0].children?.forEach(route => {
      if (route.data && route.data[0] == 0 && route.path){
        this.toMakeButtons.push(
          { "path":"/"+route.path,
            "name":<string>("Go back to "+route.title),
            "icon":route.data[1]})
      }
    });
  }
}
