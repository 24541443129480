import { Component, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { ApiGatewayService } from 'src/app/services/api-gateway.service';
import { InfoMessageService, emitInfoMessage } from 'src/app/services/info-message.service';

export interface ConnectorWithStatus {
  name: string;
  display_name: string;
  status: string;
}

@Component({
  selector: 'tab-system-status',
  templateUrl: './tab-system-status.component.html',
  styleUrls: ['./tab-system-status.component.scss']
})
export class TabSystemStatusComponent implements OnInit {
  connectorsWithStatus: ConnectorWithStatus[] = [];
  selectedConnectorWithStatus?: ConnectorWithStatus;
  statussesWithIssues: number = 0;
  subscription: Subscription;

  constructor(private infoMessageService: InfoMessageService,
              private gatewayApiService: ApiGatewayService){
                const fiveMinuteTimer = interval(5*60*1000); // 5 minutes
                this.subscription = fiveMinuteTimer.subscribe(e => this.getConnectorsStatusses());
              }

  async ngOnInit(){
    await this.getConnectors();
    await this.getConnectorsStatusses();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async getConnectors(){
    // get connectors
    await this.gatewayApiService.getConnectors()
      .then( (data) => {
        data.body?.forEach( (connector) => {
        this.connectorsWithStatus.push(
          { name: connector.name, 
            display_name: connector.display_name,
            status: 'unknown'}
          );
        });
      })
      .catch( (error) => {
        emitInfoMessage(error.status +": "+ error.statusText, this.infoMessageService)
      })
  }

  async getConnectorsStatusses(){
    // get statusses
    this.connectorsWithStatus.forEach( async (connector) => {
      await this.gatewayApiService.getConnectorStatus(connector.name)
        .then((data) => {
          if (data.body) connector.status = data.body.state;
        })
        .catch((error) => {
          emitInfoMessage(error.status +": "+ error.statusText, this.infoMessageService)
        })
    })
  }

  selectStatus(status : ConnectorWithStatus){
    if (status == this.selectedConnectorWithStatus){
      this.selectedConnectorWithStatus = undefined;
    } else {
      this.selectedConnectorWithStatus = status;
    }
  }
}
