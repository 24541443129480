<div>
    <mat-toolbar color="primary" class="container-row">
        
        <div class="middle-column">
            <div>
                <a>
                    <img class="logo" src="\assets\logo\LacunaSpace-logo-horizontal-white-bigtext-svg.svg">
                </a>
            </div>
        </div>
        <div class="middle-column">
            <div> Dashboard </div>
            
        </div>
        <!-- <div class="right-column">
            <a color='primary' mat-fab extended routerLink="profile">
                <mat-icon>account_circle</mat-icon>
                Profile
            </a>
        </div> -->
        <div class="right-column">
            <a color='primary' mat-fab extended (click)="Logout()">
                <mat-icon>logout</mat-icon>
                Logout
            </a>
        </div>
    </mat-toolbar>
</div>
