<div class="queuestatus-main">

    <div class="title">
        <h2 class="title-h2">Queue Status</h2>
    </div>

    <div class="body-text">
        Here you can check the queue status of each of the routing points from Lacuna Space.
     </div>

    <div class="list">
        <mat-list>
            <mat-list-item matTooltip="Checks every 5 minutes." [matTooltipPosition]="'right'" *ngFor="let connector of connectorsWithStatus" class="listItem" lines="10">
                <div matListItemTitle class="status">
                    <div    
                        class="status-line" 
                        [ngSwitch]="connector.status">
                        <div *ngSwitchCase="'empty'"
                            class="status-ball status-ball-empty" 
                            matTooltip="No issues with routing"
                            ></div>  
                        <div *ngSwitchCase="'busy'"
                            class="status-ball status-ball-busy" 
                            matTooltip="There are some undelivered packets in the queue. We are aware when this happens."
                            ></div>  
                        <div *ngSwitchCase="'blocked'"
                            class="status-ball status-ball-blocked" 
                            matTooltip="There are many undelivered packets in the queue. We are aware when this happens."
                            ></div> 
                        <div *ngSwitchDefault
                            class="status-ball status-ball-unknown" 
                            matTooltip="The status of this connector is unknown."
                            ></div> 
                        {{connector.display_name}}
                        <mat-icon class="info-icon" matTooltip="Show info" (click)="selectStatus(connector)">info</mat-icon>
                    </div>
                    <div *ngIf="connector==selectedConnectorWithStatus" class="info-section">
                        <div *ngIf="connector.status == 'empty'"> No packets in queue. </div>
                        <div *ngIf="connector.status == 'busy'"> Some packets are in the queue. </div>
                        <div *ngIf="connector.status == 'blocked'"> Many packets are in the queue. </div>
                        <div *ngIf="connector.status == 'unknown'"> Status of connector is unknown. </div>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</div>