<div class="pp-main">
    <div class="pp-input">
        <div [formGroup]="passPredictionForm" class="pp-row">
            <div class="as-row">
                <div class="title">
                    <h2 class="title-h2">Pass Prediction Inputs</h2>
                    <mat-icon matTooltipPosition="right" matTooltip="Minimal elevation is taken from the LPP2 almanac.">info_outlined</mat-icon>
                </div>

                <!-- DATE -->
                <div class="as-row">
                    <div class="radio-section as-row">
                        <label class="checkbox-title">Start Date for 3 day pred.*</label>
                        <mat-radio-group formControlName="dateOption" (change)="updateDatePickerState()">
                            <mat-radio-button *ngFor="let dateOption of dateOptions" [value]="dateOption">
                                {{dateOption}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <mat-form-field 
                        matTooltipPosition="above" 
                        [matTooltip]="passPredictionForm.controls.dateOption.value != this.dateOptions[0] ? 'Start date should be between 90 days ago and today.' : 'Choose custom date to enable selector'">
                        <mat-label>Start Date (UTC)</mat-label>
                        <input
                            matInput 
                            [min]="minDate" [max]="maxDate" 
                            [matDatepicker]="datepicker" 
                            formControlName="date">
                        <mat-hint> YYYY-MM-DD or use datepicker</mat-hint>
                        <mat-error *ngIf="passPredictionForm.controls.date.errors?.['required']">This field is required</mat-error>
                        <mat-error *ngIf="passPredictionForm.controls.date.errors?.['matDatepickerParse']">Format should be yyy-mm-dd</mat-error>
                        <mat-error *ngIf="passPredictionForm.controls.date.errors?.['matDatepickerMax']">Max date is today: {{maxDate.format('YYYY/MM/DD')}}</mat-error>
                        <mat-error *ngIf="passPredictionForm.controls.date.errors?.['matDatepickerMin']">Min date is 90 days ago: {{minDate.format('YYYY/MM/DD')}}</mat-error>
                        <mat-datepicker-toggle 
                            [disabled]="passPredictionForm.controls.dateOption.value == this.dateOptions[0]" 
                            [matTooltipDisabled]="passPredictionForm.controls.dateOption.value == this.dateOptions[0]"
                            matIconSuffix [for]="datepicker" color="primary" matTooltip="Open datepicker"></mat-datepicker-toggle>
                        <mat-datepicker
                            [disabled]="passPredictionForm.controls.dateOption.value == this.dateOptions[0]" 
                            #datepicker></mat-datepicker>
                    </mat-form-field>
                </div>
                
                <!-- ALMANAC -->
                <mat-form-field *ngIf="almanacVersions.length > 1">
                    <mat-label>Almanac</mat-label>
                    <mat-select formControlName="almanacVersion" matTooltip="Choose wich alamanc to use">
                    <mat-option *ngFor="let almanac of almanacVersions" [value]="almanac" >
                        {{almanac}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="passPredictionForm.controls.almanacVersion.invalid">Invalid almanac</mat-error>
                </mat-form-field>

                <!-- COUNTRY CODE -->
                <mat-form-field class="wide-formfield">
                    <mat-label>Country</mat-label>
                    <input type="text" matInput formControlName="countryCode" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let code of filteredOptions | async" [value]="code">
                                {{code.name}}
                            </mat-option>
                        </mat-autocomplete>
                    <mat-error *ngIf="passPredictionForm.controls.countryCode.invalid">Invalid, please select country</mat-error>
                    <button *ngIf="passPredictionForm.controls.countryCode.value" matSuffix mat-icon-button aria-label="Clear" (click)="passPredictionForm.controls.countryCode.reset()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <!-- SERVICE TYPES -->
                <div class="checkbox-container">
                    <div class="checkbox-section" matTooltipPosition="right" matTooltip="Select at least one">
                        <mat-label class="checkbox-title">Satellite Service Type*</mat-label>
                        <checkbox-group class="checkboxes" formControlName="serviceType" [options]="serviceTypes">
                        </checkbox-group >
                    </div>
                    <div *ngIf="passPredictionForm.controls.serviceType.errors?.['required'] && passPredictionForm.controls.serviceType.touched" class="custom-mat-error-background">
                        <mat-error class="custom-mat-error-text" >Please select at least one option</mat-error>
                    </div>
                </div>

                <!-- CONSTELLATIONS -->
                <!-- <div class="checkbox-container">
                    <div class="checkbox-section" matTooltipPosition="right" matTooltip="Select at least one">
                        <mat-label class="checkbox-title">Constellation*</mat-label>
                        <checkbox-group class="checkboxes" formControlName="constellation" [options]="constellationsForCheckbox">
                        </checkbox-group>
                    </div>
                    <div *ngIf="passPredictionForm.controls.constellation.errors?.['required'] && passPredictionForm.controls.constellation.touched" class="custom-mat-error-background">
                        <mat-error class="custom-mat-error-text">Please select at least one option</mat-error>
                    </div>
                </div> -->

                <!-- HARDWARE CAPABILITY -->
                <div class="checkbox-container">
                    <div class="checkbox-section" matTooltipPosition="right" matTooltip="Select at least one">
                        <mat-label class="checkbox-title">Device Hardware Capability*</mat-label>
                        <checkbox-group class="checkboxes" formControlName="hardwareCapability" [options]="hardwareCapabilities">
                        </checkbox-group >
                    </div>
                    <div *ngIf="passPredictionForm.controls.hardwareCapability.errors?.['required'] && passPredictionForm.controls.hardwareCapability.touched" class="custom-mat-error-background">
                        <mat-error class="custom-mat-error-text" >Please select at least one option</mat-error>
                    </div>
                </div>

            </div>
        
            <div formGroupName="location" class="as-col">
                <div class="as-row">
                    <mat-form-field class="half-formfield">
                        <mat-label>Latitude (deg)</mat-label>
                        <input type="number" matInput formControlName="latitude" inputmode="numeric">
                        <mat-error *ngIf="passPredictionForm.controls.location.controls.latitude.errors?.['required']">Field required</mat-error>
                        <mat-error *ngIf="passPredictionForm.controls.location.controls.latitude.errors?.['min']">Latitude should be > -90 deg</mat-error>
                        <mat-error *ngIf="passPredictionForm.controls.location.controls.latitude.errors?.['max']">Latitude should be < 90 deg</mat-error>
                    </mat-form-field>
                
                    <mat-form-field class="half-formfield">
                        <mat-label>Longitude (deg)</mat-label>
                        <input type="number" matInput formControlName="longitude">
                        <mat-error *ngIf="passPredictionForm.controls.location.controls.longitude.errors?.['required']">Field required</mat-error>
                        <mat-error *ngIf="passPredictionForm.controls.location.controls.longitude.errors?.['min']">Longitude should be > -180 deg</mat-error>
                        <mat-error *ngIf="passPredictionForm.controls.location.controls.longitude.errors?.['max']">Longitude should be < 180 deg</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <button 
            class="fit-button" 
            mat-raised-button 
            color="white"
            (click)="getUserLocation()"
            >Get my location</button>

            <div
                [matTooltipDisabled]="passPredictionForm.valid"
                [matTooltip]="'Please make sure all required fields are filled in'">
                    <button 
                    class="fit-button"
                    mat-raised-button 
                    color="primary" 
                    (click)="submit()"> Get Passes </button>
            </div>
        </div>
        
    </div>
    <div class="pp-output">
        <div class="title">
            <h2 class="title-h2">LPP2 Predicted Passes</h2>
        </div>
        <table mat-table [dataSource]="passDataWithGroupHeaders" class="mat-elevation-z1">
            <!-- Start Column -->
            <ng-container matColumnDef="contact_start">
                <th mat-header-cell *matHeaderCellDef> Start (UTC)</th>
                <td mat-cell *matCellDef="let element"> {{element.contact_start}} </td>
            </ng-container>
          
            <!-- TCA Column -->
            <ng-container matColumnDef="contact_tca">
                <th mat-header-cell *matHeaderCellDef> 
                    <div class="header-centered">
                        TCA (UTC)  
                        <mat-icon matTooltipPosition="above" matTooltip="Time closest approach, at which satellite has the highest elevation.">info_outlined</mat-icon> 
                    </div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.contact_tca}} </td>
            </ng-container>

            <!-- End Column -->
            <ng-container matColumnDef="contact_end">
                <th mat-header-cell *matHeaderCellDef> End (UTC)</th>
                <td mat-cell *matCellDef="let element"> {{element.contact_end}} </td>
            </ng-container>

            <!-- Max Elevation Column -->
            <ng-container matColumnDef="max_elevation">
                <th mat-header-cell *matHeaderCellDef> Max Elevation </th>
                <td mat-cell *matCellDef="let element"> {{element.max_elevation}} </td>
            </ng-container>
        
            <!-- Satellite Column -->
            <ng-container matColumnDef="satellite">
                <th mat-header-cell *matHeaderCellDef> Satellite </th>
                <td mat-cell *matCellDef="let element"> {{element.satellite}} </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="service">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="type-column">
                        <mat-icon *ngIf="element.service == 'almanac_broadcast'">satellite_alt</mat-icon>
                        <mat-icon *ngIf="element.service == 'tx'">settings_input_antenna</mat-icon>
                        {{getServiceFromItsId(element.service)}} 
                    </div>
                </td>
            </ng-container>          
          
            <!-- Group header -->
            <ng-container matColumnDef="groupHeader">
                <th mat-header-cell  *matCellDef="let group" class="group-row" colspan="6">Passes with TCA on {{group.groupName}} </th>
            </ng-container>

            <!-- Table Constructor -->
            <tr mat-header-row *matHeaderRowDef="passTableColumns" class="table-header"></tr>
            <tr mat-row *matRowDef="let row; columns: passTableColumns;" class="table-row"></tr>
            <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isInGroup" class="table-row group-row"></tr>

        </table>
        <div *ngIf="passDataFromApi.length == 0" class="no-passes-found">
            No passes found. The reason for this could be:
            <ul>
                <li> There are no passes for the specified inputs in the specified 3 days</li>
                <li> Lacuna Space does not offer service in the selected country (yet)</li>
                <li> A service type / constellation / hardware capabilities mismatch</li>
                <li> Some inputs contain errors</li>
            </ul>
        </div>
    </div>
</div>