import { Component } from '@angular/core';
import { environment } from 'src/environments/environment.production';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public envVar : boolean = environment.production;
  public envVersion : string = environment.version;

  public currentYear = new Date().getFullYear();
  
  goToLink(url: string){
    window.open(url, "_blank");
  }
  goToCookies(){}
}
