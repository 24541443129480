<div *ngFor="let message of messagesFrontendFormat">
  <motd 
      class="motd"
      [message]="message" 
      (closeMessageOfTheDayEmitter)="closeMessageOfTheDay($event)"></motd>
</div>

<h1 class="title-text title-h2">
  Hello {{username | titlecase}} <mat-icon>waving_hand</mat-icon> <br> Welcome to the Lacuna Dashboard!
</h1>

<div class="body-text">
  To help you get started you can choose one of the options below
</div>

<div class="container-col">
  <div class="container-row">
    <a *ngFor="let toMakeButton of toMakeButtons.slice(0,3)"
      mat-fab 
      extended 
      color="primary"
      class="fab-button"
      [routerLink]="toMakeButton.path">
      <mat-icon>{{toMakeButton.icon}}</mat-icon>
      {{toMakeButton.name}}
    </a>
  </div>

  <div class="container-row bottom-row">
    <a *ngFor="let toMakeButton of toMakeButtons.slice(3)"
      mat-fab 
      extended 
      color="primary"
      class="fab-button"
      [routerLink]="toMakeButton.path">
      <mat-icon>{{toMakeButton.icon}}</mat-icon>
      {{toMakeButton.name}}
    </a>
  </div>
</div>
