import { Component } from '@angular/core';
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  
  constructor(protected readonly keycloak: KeycloakService){}

  Logout(){
    this.keycloak.logout();
  }
}
