
<div class="container">
    <nav mat-tab-nav-bar 
        class="tabnavbar"
        [tabPanel]="tabPanel"
        mat-stretch-tabs="false" 
        fitInkBarToContent
        [color]="'accent'">
    <a mat-tab-link *ngFor="let link of links"
        class="tabnavbar-link"
        [active]="activeLink == link['path']"
        [routerLink]="link['path']"> 
        
        <!-- For badges, we will need some data store service to handle this -->
        <!-- <div *ngIf="link['name'] == 'Queue Status'" matBadge="2" matBadgePosition="after" matBadgeColor="warn" matBadgeSize="small" class="link-name">
            {{ link['name'] }} 
        </div>
        <div *ngIf="link['name'] != 'Queue Status'" class="link-name">
            {{ link['name'] }} 
        </div> -->

        <div class="link-name">
            {{ link['name'] }} 
        </div>
        </a>
    </nav>
</div>

<mat-tab-nav-panel #tabPanel>
    <div class="scrollable-outlet">
        <router-outlet></router-outlet>
    </div>
</mat-tab-nav-panel>

