interface FileNode {
  name: string;
  description?: string;
  type: 'file' | 'directory';
  path: string;
  filesize?: number;
  children?: FileNode[];
}

export function convertToTreeData(input: any): FileNode[] {
  const children = convertDirectories(input.directories, '');
  return children;
}

export function convertDirectories(directories: any[], parentPath: string = ''): FileNode[] {
  return directories.map(dir => ({
    name: dir.name,
    description: dir.description,
    type: 'directory' as 'directory',
    path: `${parentPath}/${dir.name}`,
    children: [
      ...convertFiles(dir.files, `${parentPath}/${dir.name}`),
      ...convertDirectories(dir.directories, `${parentPath}/${dir.name}`)
    ]
  }));
}  

export function convertFiles(files: any[], parentPath: string = ''): FileNode[] {
  return files.map(file => ({
    name: file.name,
    description: file.description,
    type: 'file' as 'file',
    path: `${parentPath}/${file.name}`,
    filesize: file.filesize
  }));
}  