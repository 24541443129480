import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {}

  public getFromApiPromise<T>(url: string, headers: HttpHeaders) : Promise<HttpResponse<T>> {
    return firstValueFrom(this.http.get<T>(url, { headers: headers, observe: 'response'}))
  }

  public putApiPromise<T>(url: string, body: any, headers: HttpHeaders) : Promise<HttpResponse<T>> {
    return firstValueFrom(this.http.put<T>(url, body, { headers: headers, observe: 'response'}))
  }

  public postApiPromise<T>(url: string, body: any, headers: HttpHeaders) : Promise<HttpResponse<T>> {
    return firstValueFrom(this.http.post<T>(url, body, { headers: headers, observe: 'response'}))
  }

  public patchApiPromise<T>(url: string, body: any, headers: HttpHeaders) : Promise<HttpResponse<T>> {
    return firstValueFrom(this.http.patch<T>(url, body, { headers: headers, observe: 'response'}))
  }

  public deleteApiPromise<T>(url: string, headers: HttpHeaders) : Promise<HttpResponse<T>> {
    return firstValueFrom(this.http.delete<T>(url, { headers: headers, observe: 'response'}))
  }

  public getFromApiPromiseAsText(url: string, headers: HttpHeaders) : Promise<HttpResponse<any>> {
    return firstValueFrom(this.http.get(url, { headers: headers, observe: 'response', responseType: 'text'}))
  }

}