// Angular
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FileSizePipe } from './utils/file-size.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';

// Keycloak
import { AppRoutingModule } from './app-routing.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

// Custom
import { HeaderComponent } from './header/header.component';
import { TabHomeComponent } from './tabs/tab-home/tab-home.component';
import { TabPassPredictionComponent } from './tabs/tab-pass-prediction/tab-pass-prediction.component';
import { TabDevicesComponent } from './tabs/tab-devices/tab-devices.component';
import { TabSystemStatusComponent } from './tabs/tab-system-status/tab-system-status.component';
import { TabAlmanacComponent } from './tabs/tab-almanac/tab-almanac.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { initializeKeycloak } from './auth-init/auth-init';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ApiService } from './services/api.service';
import { InfoMessageComponent } from './info-message/info-message.component';
import { MotdComponent } from './motd/motd.component';
import { HttpClientModule } from '@angular/common/http';
import { DeleteDeviceDialogComponent } from './delete-device-dialog/delete-device-dialog.component';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { DeviceDialogComponent } from './device-dialog/device-dialog.component';

// Material
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatBadgeModule} from '@angular/material/badge';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatSortModule } from '@angular/material/sort';
import {MatTreeModule} from '@angular/material/tree';
import { TabDownloadsComponent } from './tabs/tab-downloads/tab-downloads.component'

export const CUSTOM_DATE_FORMATS  =  {
  parse: {
    dateInput: 'YYYY-MM-DD' 
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TabHomeComponent,
    TabPassPredictionComponent,
    TabDevicesComponent,
    TabSystemStatusComponent,
    TabAlmanacComponent,
    TopNavComponent,
    UserProfileComponent,
    FooterComponent,
    NotFoundComponent,
    InfoMessageComponent,
    MotdComponent,
    DeleteDeviceDialogComponent,
    NotAllowedComponent,
    CheckboxGroupComponent,
    DeviceDialogComponent,
    TabDownloadsComponent,
    FileSizePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    HttpClientModule,
    ClipboardModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,    
    MatFormFieldModule,
    MatNativeDateModule,
    MatDividerModule,
    MatTableModule,
    MatSelectModule,
    MatBadgeModule,
    MatTooltipModule,
    MatListModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    MatSortModule,
    MatTreeModule,
    FlexLayoutModule,
  ],
  providers: [ {
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [ KeycloakService ]
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, strict : true}},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
    ApiService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
