import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData, TabDevicesComponent } from '../tabs/tab-devices/tab-devices.component';
import { RegisteredDevice } from '../services/api-gateway.service';

@Component({
  selector: 'device-dialog',
  templateUrl: './device-dialog.component.html',
  styleUrls: ['./device-dialog.component.scss']
})
export class DeviceDialogComponent {
  dialogType : 'update' | 'register'; 
  dialogDevice : RegisteredDevice;
  deviceForm = new FormGroup({
    description: new FormControl<string|undefined>(undefined,[Validators.required,Validators.maxLength(64)]),
    lorawanAddress: new FormControl<string|null>(null,[Validators.required,Validators.minLength(8)]),
    networkKey: new FormControl<string|null>(null,[Validators.required,Validators.minLength(32)])
  });

  constructor(
    public dialogRef: MatDialogRef<TabDevicesComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
  ) {
    this.dialogType = dialogData.dialogType;
    this.dialogDevice = dialogData.deviceData;
    if (this.dialogType == 'update'){
      this.deviceForm.controls.description.setValue(dialogData.deviceData.description)
      this.deviceForm.controls.lorawanAddress.setValue(dialogData.deviceData.lorawan_address)
      this.deviceForm.controls.networkKey.setValue(dialogData.deviceData.nwk_skey)
      this.deviceForm.controls.lorawanAddress.disable()
      this.deviceForm.controls.networkKey.disable()
    }
  }

  fakeNgModel(){
    // return form values to dialog data
    this.dialogDevice.description = <string>this.deviceForm.controls.description.value;
    this.dialogDevice.lorawan_address = <string>this.deviceForm.controls.lorawanAddress.value;
    this.dialogDevice.nwk_skey = <string>this.deviceForm.controls.networkKey.value;
  }

  confirmDevice(){
    this.fakeNgModel();
    this.dialogRef.close(true);
  }

  cancelDevice(){
    this.dialogRef.close(false);
  }
}
