import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { routes } from '../app-routing.module';

@Component({
  selector: 'top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent {
  links : any[] = [];
  activeLink : string;

  constructor(router:Router) {
    routes[0].children?.forEach(route => {
      if (route.data && route.data[0] >= 0 && route.path){
        this.links.push(
          {"path":"/"+route.path,
           "name":<string>(route.title)})
      }
    });

    this.activeLink = this.links[0]['path']

    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.activeLink = router.url
      }
    });
  }
}
