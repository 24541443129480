import { Component } from '@angular/core';
import { ApiGatewayService } from 'src/app/services/api-gateway.service';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { convertToTreeData } from '../../utils/tree-data-utils';
import { InfoMessageService, emitInfoMessage } from 'src/app/services/info-message.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment.production';

interface FileNode {
  name: string;
  description?: string;
  type: 'file' | 'directory';
  path: string;
  filesize?: number;
  children?: FileNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  description?: string;
  level: number;
  path: string;
  filesize?: number;
  type: 'file' | 'directory';
}

@Component({
  selector: 'tab-downloads',
  templateUrl: './tab-downloads.component.html',
  styleUrls: ['./tab-downloads.component.scss']
})

export class TabDownloadsComponent {
  public url:string = environment.url;
  selectedNode: ExampleFlatNode | null = null;

  private transformer = (node: FileNode, level: number): ExampleFlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      description: node.description,
      level: level,
      path: node.path,
      filesize: node.type === 'file' ? node.filesize : undefined,
      type: node.type
    } as ExampleFlatNode;
  }  

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  private treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private apiGatewayService: ApiGatewayService,
    private infoMessageService: InfoMessageService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.dataSource.data = [];
    this.getDownloadIndex();
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const path = params['path'];
      if (path) {
        console.log("Path:", path);
        this.getDownloadIndex().then(() => {
          // Call selectNodeWithPath after the tree data is loaded
          this.selectNodeWithPath(path);
        }).catch(error => {
          console.error("Error loading download index:", error);
        });
      }
    });
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  // Gets the download index from the API
  public getDownloadIndex(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiGatewayService.getDownloadIndex().then((response) => {
        const treeData = convertToTreeData(response.body);
        this.dataSource.data = treeData;
        resolve();
      }).catch((error) => {
        emitInfoMessage("Could not get download index: " + error?.message, this.infoMessageService);
        reject(error);
      });
    });
  }

  handleNodeClick(node: ExampleFlatNode) {
    this.selectedNode = node;
    const newPath = '/downloads?path=' + node.path.slice(1);
  
    // Update the URL without navigating
    this.location.replaceState(newPath);
  }

  isNodeSelected(node: ExampleFlatNode): boolean {
    return this.selectedNode === node;
  }
  
  toggleNode(node: ExampleFlatNode) {
    this.treeControl.toggle(node);
  }

  /* Code to find node by path query parameter */

  private selectNodeWithPath(path: string) {
    // Split the path by '/' to get individual node names
    const pathParts = path.split('/');
    let currentNode: ExampleFlatNode | undefined = undefined;
  
    // Iterate over each part of the path to find and expand the corresponding node
    for (const part of pathParts) {
      currentNode = this.findNode(part, currentNode);
      if (currentNode && currentNode.expandable) {
        this.treeControl.expand(currentNode);
      }
    }
  
    // Select the final node
    if (currentNode) {
      this.handleNodeClick(currentNode);
    }
  }
  
  private findNode(name: string, parent?: ExampleFlatNode): ExampleFlatNode | undefined {
    const startIndex = parent ? this.treeControl.getDescendants(parent).indexOf(parent) + 1 : 0;
    const nodes = this.treeControl.dataNodes.slice(startIndex);
  
    for (const node of nodes) {
      if (node.name === name) {
        return node;
      }
    }
    return undefined;
  }
  
}