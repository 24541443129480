import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { InfoMessageService } from './info-message.service';

export const DEFAULT_USERNAME : string = "Not logged in"

@Injectable({
  providedIn: 'root'
})
export class KeycloakUserService {
  public username: string = DEFAULT_USERNAME;
  public usernameService$ = new Subject<string>();

  constructor(private keycloakService: KeycloakService) {

    this.keycloakService.loadUserProfile().then(
        () => {
            this.username = this.keycloakService.getUsername();
            this.usernameService$.next(this.username);            
        });
  }
  
  userHasRole(role:string, keycloakClientId:string) {
    return this.keycloakService.isUserInRole(role,keycloakClientId)
  }

  getUserAttributes() : any{
    console.log(this.keycloakService.getKeycloakInstance().tokenParsed, 'bing')
    return this.keycloakService.getKeycloakInstance().tokenParsed?.['attributes']
  }

  getUserAllowedAlmanacs() : string[] {
    let attributes = this.getUserAttributes();
    if (!attributes) { 
        return ['default'];
    } else {
        let almanacs = attributes['almanacs'];
        if (!almanacs) {
            return ['default'];
        } else {
            let almanacList = almanacs.indexOf(',') === -1 ? [almanacs] : almanacs.split(',');
            if (!almanacList.includes('default')) {
                almanacList.push('default');
            }
            return almanacList;
        }
    }
}


  getUserMaxDevices() : number {
    let attributes : any = this.getUserAttributes()
    if (!attributes){ return 5}
    else {
      let maxDevicesUser = attributes['max_devices']
      if (!maxDevicesUser) return 5
      else return maxDevicesUser
    }
  }

}
