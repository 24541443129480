<div class="devices-main">
    <div class="devices-input">
        <h2 class="title-h2">Device Registration</h2>

        <div class="body-text">
           Here you can register or deregister your devices. This allows you can see the device's sent messages that are received by 
           our satellites since the registration of the device. 
           <br>
           <br>
           The payload shown in the received messages is the encrypted LoraWAN packet. Lacuna Space cannot decrypt it, since it does not have the application key. 
        </div>

        <div class="as-col">
            <button 
                class="main-devices-button"
                mat-raised-button 
                color="primary"
                (click)="openDialogDevice('register')"> Register new device </button>
        </div>       

    </div>
    <div class="devices-output">
        <h2 class="title-h2">Registered Devices ({{registeredDevices.data.length}}/{{maxUserDevices}})</h2>
        <section class="devices-table mat-elevation-z1">
            <table mat-table [dataSource]="registeredDevices" matSort #sorterDevices="matSort">
                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <!-- lorawan address Column -->
                <ng-container matColumnDef="lorawan_address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> LoraWAN Address </th>
                    <td mat-cell *matCellDef="let element"> {{element.lorawan_address}} </td>
                </ng-container>

                <!-- Network Column -->
                <ng-container matColumnDef="nwk_skey">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Network Key </th>
                    <td mat-cell *matCellDef="let element" class="text-monospace"> {{element.nwk_skey}} </td>
                </ng-container>

                <!-- Description Column -->
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                </ng-container>

                <!-- F counter Column -->
                <ng-container matColumnDef="last_fcnt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last F counter </th>
                    <td mat-cell *matCellDef="let element"> {{element.last_fcnt}} </td>
                </ng-container>

                <!-- last seen Column -->
                <ng-container matColumnDef="last_seen">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Seen </th>
                    <td mat-cell *matCellDef="let element"> {{element.last_seen}} </td>
                </ng-container>

                <!-- Delete Column -->
                <ng-container matColumnDef="buttons">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" 
                        (click)="$event.stopPropagation()"
                        class="button-container"> 
                        <button 
                            mat-icon-button 
                            color="accent"
                            matTooltip="Edit device description"
                            (click)="openDialogDevice('update',element)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button 
                            mat-icon-button 
                            color="warn"
                            matTooltip="Delete device"
                            (click)="confirmDelete(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="registeredDevicesTableColumns" class="table-header"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: registeredDevicesTableColumns"
                    class="element-row"
                    (click)="selectedDevice = selectedDevice === row ? null : row"
                    (click)="updateSelectedDeviceMessages()"
                    [class.expanded-row]="selectedDevice === row"></tr>
            </table>
        </section>
        <div class="no-items-found" *ngIf="registeredDevices.data.length == 0">
            No devices found / registered
        </div>

        <div >
            <h2 class="title-h2">Received Messages</h2>
            <div  class="received-messages-text">
                <label>{{ selectedDevice? 'Latest messages for '+ selectedDevice.lorawan_address + ' / ' + selectedDevice.description : 'Please select device'}}</label>
            </div>

            <section class="messages-table mat-elevation-z1">
                <table *ngIf="selectedDevice" mat-table [dataSource]="selectedDeviceMessages" class="mat-elevation-z1" matSort #sorterMessages="matSort">
                    <!-- Id Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>

                    <!-- Reception Date Column -->
                    <ng-container matColumnDef="reception_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Reception Date</th>
                        <td mat-cell *matCellDef="let element"> {{element.reception_date}} </td>
                    </ng-container>

                    <!-- Hub Date Column -->
                    <ng-container matColumnDef="hub_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Hub Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.hub_date}} </td>
                    </ng-container>

                    <!-- Done Date Column -->
                    <ng-container matColumnDef="done_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivered Date</th>
                        <td mat-cell *matCellDef="let element"> {{element.done_date}} </td>
                    </ng-container>

                    <!-- Payload Column -->
                    <ng-container matColumnDef="payload">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Payload (encrypted LoraWAN packet)</th>
                        <td mat-cell *matCellDef="let element"> {{element.payload}} </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="selectedDeviceMessagesTableColumns" class="messages-table-header"></tr>
                    <tr mat-row *matRowDef="let row; columns: selectedDeviceMessagesTableColumns" class="messages-table-row"></tr>
                </table>
                
            </section>
            <div class="no-items-found" *ngIf="selectedDevice != undefined && selectedDeviceMessages.data.length == 0">
                No messages found
            </div>
        </div>
    </div>
</div>
