<div [formGroup]="deviceForm">
    <h1 mat-dialog-title class="label-device-option">Register Device</h1>
    <div mat-dialog-content class="form-column">        
        <!-- <label class="label-device-option"><b>Register Device</b></label> -->
        <mat-form-field>
            <mat-label>Name/Description</mat-label>
            <input type="text" matInput formControlName="description">
            <mat-error *ngIf="deviceForm.controls.description.errors?.['required']">Field is required</mat-error>
            <mat-error *ngIf="deviceForm.controls.description.errors?.['maxlength']">Max 64 characters</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>LoraWAN Address</mat-label>
            <input type="text" matInput formControlName="lorawanAddress" maxlength="8">
            <mat-hint>Format: 8-alphanumeric </mat-hint>
            <mat-error *ngIf="deviceForm.controls.lorawanAddress.errors?.['required']">Field is required</mat-error>
            <mat-error *ngIf="deviceForm.controls.lorawanAddress.errors?.['minlength']">Needs to be 8 characters</mat-error>
        </mat-form-field>

        <mat-form-field class="text-monospace">
            <mat-label>Network Key</mat-label>
            <input type="text" matInput formControlName="networkKey" maxlength="32">
            <mat-hint>Format: 32-alphanumeric</mat-hint>
            <mat-error *ngIf="deviceForm.controls.networkKey.errors?.['required']">Field is required</mat-error>
            <mat-error *ngIf="deviceForm.controls.networkKey.errors?.['minlength']">Needs to be 32 characters</mat-error>
        </mat-form-field>

        <button mat-raised-button (click)="confirmDevice()" 
                [mat-dialog-close]="dialogData" [disabled]="!deviceForm.valid" color="primary" > {{dialogType | titlecase}} Device</button>
        <button mat-raised-button (click)="cancelDevice()" color="black">Cancel</button>

    </div>
</div>