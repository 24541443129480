<div class="container">
    <div class="title-text">
        Sorry, page not found!
    </div>

    <a *ngFor="let toMakeButton of toMakeButtons.slice(0,2)"
                    mat-fab 
                    extended 
                    color="primary"
                    class="button"
                    [routerLink]="toMakeButton.path">
                    {{toMakeButton.name}}
    </a>
</div>