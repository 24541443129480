import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlmanacPass, ApiGatewayService, ConstellationCode, CountryCode, HARDWARE_CAPABILITIES, HardwareCapability, SERVICE_TYPES, ServiceType } from 'src/app/services/api-gateway.service';
import { KeycloakUserService } from 'src/app/services/keycloak-user.service';
import { InfoMessageService, emitInfoMessage } from 'src/app/services/info-message.service';
import { Observable, startWith, map } from 'rxjs';
import { autocompleteObjectValidator } from './pass-prediction-validators';
import * as moment from 'moment';
import { CheckboxGroupInput } from 'src/app/checkbox-group/checkbox-group.component';
import { environment } from 'src/environments/environment.production';

export interface GroupDayName {
  groupName: string;
}

export const LACUNA_LOCAL_STORAGE_PP_NAME : string = 'lacuna-dashboard-store-passprediction-inputs'

@Component({
  selector: 'tab-pass-prediction',
  templateUrl: './tab-pass-prediction.component.html',
  styleUrls: ['./tab-pass-prediction.component.scss']
})
export class TabPassPredictionComponent {
  passTableColumns = ['contact_start','contact_tca','contact_end','max_elevation','satellite','service']
  passDataFromApi : AlmanacPass[] = [];
  passDataWithGroupHeaders : (AlmanacPass|GroupDayName)[] = [];
  countryCodes : CountryCode[] = [];
  almanacVersions : string[] = [];
  serviceTypes : ServiceType[] = SERVICE_TYPES;
  // constellations : ConstellationCode[] = [];
  // constellationsForCheckbox : CheckboxGroupInput[] = [];
  hardwareCapabilities : HardwareCapability[] = HARDWARE_CAPABILITIES;
  minDate : moment.Moment = moment().add(-90,'days');
  maxDate : moment.Moment = moment();
  todayDate : moment.Moment = moment();
  filteredOptions?: Observable<CountryCode[]>;
  dateOptions : string[] = ['Today','Custom'];

  passPredictionForm = new FormGroup({
    dashboardVersion: new FormControl<string>(environment.version),
    dateOption: new FormControl<string|null>(this.dateOptions[0],[Validators.required]),
    date: new FormControl<moment.Moment>(this.todayDate,[Validators.required]),
    countryCode: new FormControl<CountryCode|null>(null,[Validators.required, autocompleteObjectValidator()]),
    almanacVersion: new FormControl<string|null>(null,Validators.required),
    serviceType: new FormControl<any|null>([],[Validators.required, Validators.minLength(1)]),
    // constellation: new FormControl<any|null>([],[Validators.required, Validators.minLength(1)]),
    hardwareCapability: new FormControl<any|null>([],[Validators.required, Validators.minLength(1)]),
    location: new FormGroup({
      latitude: new FormControl<number|null>(null,[Validators.required, Validators.min(-90),Validators.max(90)]),
      longitude: new FormControl<number|null>(null,[Validators.required, Validators.min(-180),Validators.max(180)]),
    })
  });

  constructor(private infoMessageService: InfoMessageService,
              private gatewayApiService: ApiGatewayService,
              private keycloakUserService: KeycloakUserService){}

  async ngOnInit(){
    // Almanacs
    this.almanacVersions = this.keycloakUserService.getUserAllowedAlmanacs();

    // Init values
    // WARNING: This needs to be here, before API calls, otherwise Safari has a bug rendering the form
    const initedFromLocalStorage : boolean = this.initFromLocalStorage();
    if (!initedFromLocalStorage) emitInfoMessage("Pass Prediction: Local storage contained older dashboard version data, ignoring.", this.infoMessageService);

    // Countries
    await this.getCountryCodes();

    // Constellations
    // await this.getConstellationOptions();

    // init clean values if local storage failed to be read
    if (!initedFromLocalStorage) this.initWithoutLocalStorage();

    // Make country autocomplete
    this.filteredOptions = this.passPredictionForm.controls.countryCode.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.countryCodes.slice();
      }),
    );

    // Make sure datepicker is disabled or enabled
    this.updateDatePickerState();
  }

  ngOnDestroy(){
    localStorage.setItem(LACUNA_LOCAL_STORAGE_PP_NAME, JSON.stringify(this.passPredictionForm.getRawValue()));
  }

  /**
   * Returns boolean that shows if local storage was used
   */
  initFromLocalStorage() : boolean {
    // If cookie is found, patch those values (but reset the date input, to avoid errors)
    let localData = localStorage.getItem(LACUNA_LOCAL_STORAGE_PP_NAME)
    // check if local storage exists
    if (!localData) {
      return false
    }
    // check if dashboard version matches local storage version
    let localDataAsObj : any = JSON.parse(localData);
    if (localDataAsObj['dashboardVersion'] != environment.version){
      return false
    }
    // Change local storage object to correct formats
    localDataAsObj['date'] = moment(localDataAsObj['date'])
    localDataAsObj['location']['latitude'] = localDataAsObj['location']['latitude'] == null? null : parseFloat(localDataAsObj['location']['latitude'])
    localDataAsObj['location']['longitude'] = localDataAsObj['location']['longitude'] == null? null : parseFloat(localDataAsObj['location']['longitude'])
    if (localDataAsObj['almanacVersion'] == null) localDataAsObj['almanacVersion'] = this.almanacVersions[0]
    // Patch values
    this.passPredictionForm.patchValue(localDataAsObj);
    return true
  }

  initWithoutLocalStorage() : void {
    // DateOption
    this.passPredictionForm.controls.dateOption.setValue(this.dateOptions[0])
    // Almanacs
    this.passPredictionForm.controls.almanacVersion.setValue(this.almanacVersions[0])
    // Services
    this.passPredictionForm.controls.serviceType.setValue(this.serviceTypes.map(e=>e.id))
    // Constellations
    // this.passPredictionForm.controls.constellation.setValue(this.constellationsForCheckbox.map(e=>e.id))
    // Hardware Capabilities
    this.passPredictionForm.controls.hardwareCapability.setValue(this.hardwareCapabilities.map(e=>e.id))
  }

  updateDatePickerState(){
    if (this.passPredictionForm.controls.dateOption.value == this.dateOptions[0]){
      this.passPredictionForm.controls.date.disable();
    } else {
      this.passPredictionForm.controls.date.enable();
    }
  }

  // async getConstellationOptions(){
  //   // Make expected data array
  //   await this.gatewayApiService.getPassPredictionConstellationsOptions()
  //   .then((data) => {
  //     if (data.body) this.constellations = data.body
  //   })
  //   // Convert to a structure checkboxes understand
  //   this.constellations.forEach((e) => {
  //     this.constellationsForCheckbox.push({ name:e.name.charAt(0).toUpperCase() + e.name.slice(1).toLowerCase(),
  //                                           id: String(e.bit_number)})
  //   })
  // }

  async getCountryCodes(){
    await this.gatewayApiService.getPassPredictionCountriesOptions()
    .then((data) => {
      if (data.body) this.countryCodes = data.body
    })
  }

  async getUserLocation(){
    if (navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position) => {
        this.passPredictionForm.controls.location.controls.latitude.setValue(parseFloat(position.coords.latitude.toFixed(6)));
        this.passPredictionForm.controls.location.controls.longitude.setValue(parseFloat(position.coords.longitude.toFixed(6)));
      }, (error) => {
        if (error.code == error.PERMISSION_DENIED) emitInfoMessage("Could not get your location: Permission Denied", this.infoMessageService)
        else if (error.code == error.POSITION_UNAVAILABLE) emitInfoMessage("Could not get your location: Position Unavailable", this.infoMessageService)
        else if (error.code == error.TIMEOUT) emitInfoMessage("Could not get your location: Timed Out", this.infoMessageService)
        else emitInfoMessage("Could not get your location: unknown error", this.infoMessageService);
        console.log("error message:",error.message);
      },{maximumAge:20000, timeout:10000});
    } else {
      emitInfoMessage("Could not get your location: Service not available in browser.", this.infoMessageService)
    };
  }

  async submit(){
    this.passPredictionForm.markAllAsTouched();
    // Date
    let dateFrom: moment.Moment | undefined;
    if (this.passPredictionForm.controls.dateOption.value == this.dateOptions[0]){
      // If Today
      dateFrom = this.todayDate;
    } else {
      // Check given custom date
      console.log(this.passPredictionForm.controls.date.value)
      if (this.passPredictionForm.controls.date.value == null || !this.passPredictionForm.controls.date.value?.isValid()){
        emitInfoMessage("Pass prediction: Need to supply a date",this.infoMessageService)
        return
      }
      if (!this.passPredictionForm.controls.date.valid){
        emitInfoMessage("Pass prediction: Need to supply a valid date",this.infoMessageService)
        return
      }
      // Use given date
      dateFrom = this.passPredictionForm.controls.date.value;
    }
    // Almanac
    if (this.passPredictionForm.controls.almanacVersion.value == null){
      emitInfoMessage("Pass prediction: Need to select an almanac",this.infoMessageService)
      return
    }
    // Country
    if (this.passPredictionForm.controls.countryCode.value == null){
      emitInfoMessage("Pass prediction: Need to select a country",this.infoMessageService)
      return
    }
    if (this.passPredictionForm.controls.countryCode.invalid){
      emitInfoMessage("Pass prediction: Need to supply a valid country from the list",this.infoMessageService)
      return
    }
    // Service
    if (this.passPredictionForm.controls.serviceType.value.length == 0){
      emitInfoMessage("Pass prediction: Need to select at least one service type",this.infoMessageService)
      return
    }
    // Constellation
    // if (this.passPredictionForm.controls.constellation.value.length == 0){
    //   emitInfoMessage("Pass prediction: Need to select at least one constellation",this.infoMessageService)
    //   return
    // }
    // Hardware caps
    if (this.passPredictionForm.controls.hardwareCapability.value.length == 0){
      emitInfoMessage("Pass prediction: Need to select at least one hardware capability",this.infoMessageService)
      return
    }
    // Location
    if (this.passPredictionForm.controls.location.controls.latitude.value == null){
      emitInfoMessage("Pass prediction: Need to supply a latitude",this.infoMessageService)
      return
    }
    if (this.passPredictionForm.controls.location.controls.longitude.value == null){
      emitInfoMessage("Pass prediction: Need to supply a longitude",this.infoMessageService)
      return
    }
    if (this.passPredictionForm.controls.location.invalid){
      emitInfoMessage("Pass prediction: Need to supply a valid location",this.infoMessageService)
      return
    }
    let longitudeValue = this.passPredictionForm.controls.location.controls.longitude.value
    if (this.passPredictionForm.controls.location.controls.longitude.value == 180){
      longitudeValue = -180
    }
    // API call
    await this.gatewayApiService.getAlmanacPasses(
      // required
      this.passPredictionForm.controls.almanacVersion.value,
      this.passPredictionForm.controls.countryCode.value.code,
      this.passPredictionForm.controls.location.controls.latitude.value,
      longitudeValue,
      // optional
      this.passPredictionForm.controls.serviceType.value,
      // this.passPredictionForm.controls.constellation.value,
      this.passPredictionForm.controls.hardwareCapability.value,
      dateFrom)
    .then((data) => {
      if (data.body){
        this.passDataFromApi = data.body
        this.passDataFromApi.forEach((pass : AlmanacPass) => {
            pass.contact_start = pass.contact_start.replace('T',' ').replace('Z','');
            pass.contact_tca = pass.contact_tca.replace('T',' ').replace('Z','');
            pass.contact_end = pass.contact_end.replace('T',' ').replace('Z','');
          });
        if (this.passDataFromApi.length == 0){
          emitInfoMessage("No passes found", this.infoMessageService)
        }
      };
      this.createPassDataWithGroupHeaders();
      localStorage.setItem(LACUNA_LOCAL_STORAGE_PP_NAME, JSON.stringify(this.passPredictionForm.getRawValue()));
    })
    .catch((error) => {
      emitInfoMessage("Error:" + error.status + " " + error.statusText, this.infoMessageService)
    })
  }

  getServiceFromItsId(service_id : string){
    let returnString: string = service_id;
    SERVICE_TYPES.forEach((serviceType) => {
      if (serviceType.id == service_id){
        returnString = serviceType.name
      }
    })
    return returnString
  }

  isInGroup(index : number, item : any): boolean {
    return item.groupName;
  }

  isGroup(item : AlmanacPass | GroupDayName): item is GroupDayName {
    return 'groupName' in item;
  }

  createPassDataWithGroupHeaders(){
    // Reset this.passDataWithGroupHeaders
    this.passDataWithGroupHeaders = [];
    for (let index = 0; index < this.passDataFromApi.length; index++) {
      let thisPass : AlmanacPass = this.passDataFromApi[index];
      let nextPass : AlmanacPass = this.passDataFromApi[index+1];
      // If this pass is first in table, add group header and this pass
      if (index == 0){
        let group : GroupDayName = {'groupName' : thisPass.contact_tca.split(' ')[0]}
        this.passDataWithGroupHeaders.push(group)
      }
      // Add this pass in any case
      this.passDataWithGroupHeaders.push(thisPass);
      // If next pass exist, check if it is a new group, if so add new group header
      if (nextPass != undefined){
        if (thisPass.contact_tca.split(' ')[0].split('-')[2] != nextPass.contact_tca.split(' ')[0].split('-')[2]){
          let group : GroupDayName = {'groupName' : nextPass.contact_tca.split(' ')[0]}
          this.passDataWithGroupHeaders.push(group)
        }
      }
    }
  }

  displayFn(user: CountryCode): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): CountryCode[] {
    const filterValue = name.toLowerCase();
    return this.countryCodes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  objToArray(obj : any){
    let optionsChosen : string[] = [];
    let key : string;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
          if ((obj as any)[key]) optionsChosen.push(key);
      }
    }
  }

}
