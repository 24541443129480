import { Component } from '@angular/core';
import { KeycloakUserService } from './services/keycloak-user.service';
import { environment } from 'src/environments/environment.production';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dashboard-frontend';
  userIsAllowed : boolean = false;

  constructor(private keycloakUserService : KeycloakUserService){
    this.userIsAllowed = this.keycloakUserService.userHasRole(environment.production? 'prod':'dev','gateway');
  }

}
