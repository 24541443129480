<div>
    <mat-toolbar color="primary" class="container-row">
        <div class="left-column">
            <div class="version">Version: {{envVersion}}</div>
            <div *ngIf="!envVar" class="version">
                env_prod: {{envVar}}
            </div>
        </div>
        <div class="middle-column">
            <div> 
                ©{{this.currentYear}} Lacuna Space Ltd.
            </div>
            <div> 
                All rights reserved.
            </div>
        </div>
        <div class="right-column">
            <button mat-icon-button 
                matTooltip="Go to cookie statement"
                [matTooltipPosition]="'above'"
                (click)="goToLink('/privacy-policy.html#cookies')">
                <mat-icon color="white">
                    cookie
                </mat-icon>
            </button>
            <button mat-icon-button 
                matTooltip="Go to privacy statement"
                [matTooltipPosition]="'above'"
                (click)="goToLink('/privacy-policy.html')">
                <mat-icon>
                    policy
                </mat-icon>
            </button>
        </div>
    </mat-toolbar>
</div>