import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { InfoMessageService } from '../services/info-message.service';

@Component({
  selector: 'info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss']
})
export class InfoMessageComponent {
  @Input() public infoMessage:Subscription;

  constructor(private _snackBar: MatSnackBar,
              public infoMessageService: InfoMessageService) {
    this.infoMessage = this.infoMessageService.infoMessage$.subscribe($event => {
      this.openSnackBar($event);
                })
              }

  async openSnackBar(infoMessage:string) {
    if (infoMessage != ''){
      this._snackBar.open(infoMessage, "Close",{
        duration: 5000,
      });
      console.log("Info message:",infoMessage);
    }
  }
}
