<div class="downloads-main">

  <div>
    <h2 class="title">Downloads</h2>
  </div>

  <div class="body-text">
    Here you can view a list of all currently available downloads, click a folder icon in the tree to expand it.
  </div>

  <!-- Flex container -->
  <div fxLayout="row" fxLayoutGap="16px">

    <!-- First card -->
    <mat-card fxFlex="50" class="navigator-card">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="scroll-shadow scrollable">
        <!-- File Node -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding (click)="handleNodeClick(node)" [ngClass]="{ selected: isNodeSelected(node) }">
          <button mat-icon-button disabled></button>
          <mat-icon class="material-symbols-outlined">note</mat-icon>  <!-- File Icon -->
          {{node.name}} : {{node.filesize | fileSize}}&nbsp;
        </mat-tree-node>
        <!-- Directory Node -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding (click)="handleNodeClick(node)" (dblclick)="toggleNode(node)" [ngClass]="{ selected: isNodeSelected(node) }">
          <button mat-icon-button [attr.aria-label]="'toggle ' + node.name" matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-icon class="material-symbols-outlined">{{treeControl.isExpanded(node) ? 'folder_open' : 'folder'}}</mat-icon>  <!-- Dynamically change icon -->
          {{node.name}}
        </mat-tree-node>
      </mat-tree>
    </mat-card>

    <!-- Second card -->
    <mat-card fxFlex="50" class="download-card">
      <div class="header">
        <h1>{{ selectedNode?.name || 'Nothing Selected'}}</h1>
        <mat-divider></mat-divider>
      </div>
      <div [innerHTML]="selectedNode?.description" class="description scroll-shadow scrollable"></div>
      <div *ngIf="selectedNode?.name && selectedNode?.type === 'file'">
        <mat-divider></mat-divider>
        <div class="download-button">
          <a [href]="url + '/download' + selectedNode?.path" download="{{selectedNode?.name}}">
            <button mat-fab extended color="primary">
              <mat-icon class="material-symbols-outlined">cloud_download</mat-icon>
              Download
            </button>
          </a>
        </div>
      </div>
    </mat-card>
  </div>
</div>
