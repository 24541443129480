import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface MessageOfTheDay {
  severity: string,
  textTag: string,
  textBody: string
 }

@Component({
  selector: 'motd',
  templateUrl: './motd.component.html',
  styleUrls: ['./motd.component.scss']
})
export class MotdComponent {
  @Input() public message? : MessageOfTheDay;
  @Output() closeMessageOfTheDayEmitter: EventEmitter<any> = new EventEmitter();

  close(message:MessageOfTheDay | undefined){
    if (message != undefined){
      this.closeMessageOfTheDayEmitter.emit(message)
    }
  }
}
