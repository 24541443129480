import { Component, Inject } from '@angular/core';
import { DialogRemoveData, TabDevicesComponent } from '../tabs/tab-devices/tab-devices.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegisteredDevice } from '../services/api-gateway.service';

@Component({
  selector: 'delete-device-dialog',
  templateUrl: './delete-device-dialog.component.html',
  styleUrls: ['./delete-device-dialog.component.scss']
})
export class DeleteDeviceDialogComponent {
  device : RegisteredDevice;

  constructor(
    public dialogRef: MatDialogRef<TabDevicesComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogRemoveData,
  ) {
    this.device = dialogData.deviceData;
  }
  
  confirmDevice(){
    this.dialogRef.close(true);
  }

  cancelDevice(){
    this.dialogRef.close(false);
  }
}
