import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment.production";

export function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
      keycloak.init({
        config: environment.keyCloakConfig,
        initOptions: environment.keyCloakInitOptions
      });
  }
  