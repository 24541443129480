import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export async function emitInfoMessage(message: string, infoMessageService: InfoMessageService){
  infoMessageService.infoMessage$.next(message);
  await delay(1);
  infoMessageService.infoMessage$.next("");
}

export function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

@Injectable({
  providedIn: 'root'
})
export class InfoMessageService {
  infoMessage$ = new Subject<string>();

  constructor() { }
}
