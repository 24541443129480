import { Component, Input } from '@angular/core';
import { ValueAccessorBase } from '../utils/valueAccessorBase';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export interface CheckboxGroupInput{
  name: string,
  id: string
}

@Component({
  selector: 'checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CheckboxGroupComponent,
    multi: true
  }]
})
export class CheckboxGroupComponent extends ValueAccessorBase<string[]> {
  @Input() options?: Array<CheckboxGroupInput> = [];
  public collectedBooleans : any = {};

  booleansChanged(){
    let result : string[] = [];
    Object.entries(this.collectedBooleans).forEach(([key,value]) => {
      if (value) result.push(key)
    })
    this.setInnerValueAndNotify(result);
  }

  override writeValue(value: string[]): void {
    this.collectedBooleans = {};
    if (!value) return
    value.forEach((key) => {
      this.collectedBooleans[key] = true;
    })
  }
}
