import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabHomeComponent } from './tabs/tab-home/tab-home.component';
import { TabPassPredictionComponent } from './tabs/tab-pass-prediction/tab-pass-prediction.component';
import { TabDevicesComponent } from './tabs/tab-devices/tab-devices.component';
import { TabSystemStatusComponent } from './tabs/tab-system-status/tab-system-status.component';
import { TabAlmanacComponent } from './tabs/tab-almanac/tab-almanac.component';
import { TabDownloadsComponent } from './tabs/tab-downloads/tab-downloads.component';
import { AuthGuard } from './auth-guard/auth-guard';
import { NotFoundComponent } from './not-found/not-found.component';

export const routes: Routes = [
    // This order is maintained!
    // data index 0 will be rendered in the top nav when it has a positive number
  { path: '', canActivate: [ AuthGuard ], // this is the component with the <router-outlet> in the template
  data: [-1, 'satellite_alt'],
  title: 'redictect_to_home',   
  children: [
      { path:'', 
        redirectTo: 'home', 
        pathMatch: 'full'},
      { path:'home', 
        title: 'Home', 
        component: TabHomeComponent,
        data: [0,'satellite_alt']  },
      { path:'pass_prediction', 
        title: 'Pass Prediction', 
        component: TabPassPredictionComponent,
        data: [1,'satellite_alt']  },
      { path:'devices', 
        title: 'Device Registration', 
        component: TabDevicesComponent,
        data: [2,'devices']  },
      { path:'delivery_status', 
        title: 'Queue Status', 
        component: TabSystemStatusComponent,
        data: [3,'cloud_upload']  },
      { path:'almanac', 
        title: 'Almanac Download', 
        component: TabAlmanacComponent,
        data: [4,'satellite']  },
      { path:'downloads',
        title: 'Downloads',
        component: TabDownloadsComponent,
        data: [5,'download']  },
      {path:'notfound', component: NotFoundComponent },
      {path:'privacy-policy',redirectTo:'/privacy-policy.html',pathMatch: 'full'},
      {path:'**', redirectTo: 'notfound' },
      ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
